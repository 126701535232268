import React from 'react';

export function BDay() {
    return (<>
        <img className='bday' src='./images/bday.webp'></img>
        <div className="balloons" aria-hidden="true">
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
            <div className="balloon">
                <div className="inner">🎈</div>
            </div>
        </div>
    </>);
}
